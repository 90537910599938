import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import network from './network/reducer';
import networkDevice from './networkDevice/reducer';
import fwUpdate from './fwUpdate/reducer';
import system from './system/reducer';

export default () =>
  combineReducers({
    toastr: toastrReducer,
    network,
    networkDevice,
    fwUpdate,
    system
  });
