import colors from 'assets/theme/base/colors';
const { dark } = colors;

const listItemText = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      color: dark.main
    }
  }
};

export default listItemText;
