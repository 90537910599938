import { all, takeEvery, call, put } from 'redux-saga/effects';
import { list, count, create, detail, update, remove } from 'services/apis/network';
import actions from './actions';
import { history } from 'redux/store';
import { toastr } from 'react-redux-toastr';

function* loading(isLoading = false, error = false) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: isLoading,
      error
    }
  });
}

export function* LIST({ payload }) {
  yield loading(true);

  const response = yield call(list, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        list: response,
        isNetworkFetched: true,
        isCountFetched: false
      }
    });

    if (payload.token) {
      history.push('/');
    }
  }
  if (!response) {
    yield loading(false, true);
  }
}

export function* COUNT({ payload }) {
  yield loading(true);

  const response = yield call(count, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        count: response.count,
        isNetworkFetched: false,
        isCountFetched: true
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* CREATE({ payload }) {
  const { body, filter } = payload;
  let whereCondition = {};
  yield loading(true);

  if (filter.where) {
    whereCondition = filter.where;
  }

  const response = yield call(create, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false
      }
    });

    yield put({ type: actions.COUNT, payload: { where: whereCondition } });
    toastr.success('succeeded', 'created new network!');
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* DETAIL({ payload }) {
  const { id } = payload;
  let filter = {};
  yield loading(true);

  if (payload.filter) {
    filter = payload.filter;
  }

  const response = yield call(detail, id, filter);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* UPDATE({ payload }) {
  const { id, body, filter } = payload;
  yield loading(true);

  const response = yield call(update, id, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false
      }
    });
    if (filter.where) {
      yield put({ type: actions.COUNT, payload: { where: filter.where } });
    } else {
      yield put({ type: actions.LIST, payload: { filter: JSON.stringify(filter) } });
    }

    toastr.success('succeeded', 'network updated!');
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* DELETE({ payload }) {
  const { id, filter } = payload;
  let whereCondition = {};
  yield loading(true);

  if (filter.where) {
    whereCondition = filter.where;
  }

  const response = yield call(remove, id);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false,
        isRemoved: true
      }
    });

    yield put({ type: actions.COUNT, payload: { where: whereCondition } });
    toastr.success('succeeded', 'network removed!');
  }

  if (!response) {
    yield loading(false, true);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.COUNT, COUNT),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DETAIL, DETAIL),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE)
  ]);
}
