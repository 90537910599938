import { all, takeEvery, call, put } from 'redux-saga/effects';
import { list } from 'services/apis/system';
import actions from './actions';

function* loading(isLoading = false, error = false) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: isLoading,
      error
    }
  });
}

export function* LIST({ payload }) {
  yield loading(true);

  const response = yield call(list, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        list: response,
        isFwUpdatesFetched: true,
        isCountFetched: false
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LIST, LIST)]);
}
