import apiClient from 'services/axios';
import { replaceUrlIds } from 'utils';

const API = {
  list: 'api/networks/{networkId}/services/fwUpdate/authorizes',
  count: 'api/networks/{networkId}/services/fwUpdate/authorizes/count',
  create: 'api/networks/{networkId}/services/fwUpdate/authorizes',
  delete: 'api/networks/{networkId}/services/fwUpdate/authorizes/{authorizeId}',
  getFwUpdateStatus: 'api/networks/{networkId}/cfg/system',
  setFwUpdateStatus: 'api/networks/{networkId}/cfg/system',
  triggerFwUpdate: 'api/networks/{networkId}/services/system/{deviceId}'
};

export const list = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.list, [{ networkId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const count = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.count, [{ networkId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const create = async (id, body) => {
  const parsedUrl = replaceUrlIds(API.create, [{ networkId: id }]);
  const response = await apiClient.post(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const remove = async (networkId, authorizeId) => {
  const parsedUrl = replaceUrlIds(API.delete, [{ networkId }, { authorizeId }]);
  const response = await apiClient.delete(parsedUrl);
  if (response) {
    return response.data;
  }
  return false;
};

export const getFwUpdateStatus = async (id) => {
  const parsedUrl = replaceUrlIds(API.getFwUpdateStatus, [{ networkId: id }]);
  const response = await apiClient.get(parsedUrl);
  if (response) {
    return response.data;
  }
  return false;
};

export const setFwUpdateStatus = async (id, body) => {
  const parsedUrl = replaceUrlIds(API.setFwUpdateStatus, [{ networkId: id }]);
  const response = await apiClient.patch(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const triggerFwUpdate = async (networkId, deviceId, body) => {
  const parsedUrl = replaceUrlIds(API.triggerFwUpdate, [{ networkId }, { deviceId }]);
  const response = await apiClient.post(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};
