import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './context/auth.context';
import store from 'store';

export const ProtectedRoute = ({ ...rest }) => {
  const accessToken = store.get('token');
  const { user } = useAuth();

  if ((!user || !user.token || user.token === '') && !accessToken) {
    return <Redirect to="/login" />;
  }
  return <Route {...rest} />;
};
