const actions = {
  SET_STATE: 'fwUpdate/SET_STATE',
  LIST: 'fwUpdate/LIST',
  LIST_DROPDOWN: 'fwUpdate/LIST_DROPDOWN',
  COUNT: 'fwUpdate/COUNT',
  CREATE: 'fwUpdate/CREATE',
  DELETE: 'fwUpdate/DELETE',
  GET_FW_UPDATE_STATUS: 'fwUpdate/GET_FW_UPDATE_STATUS',
  SET_FW_UPDATE_STATUS: 'fwUpdate/SET_FW_UPDATE_STATUS'
};

export default actions;
