import { useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SidenavCollapse from 'layouts/Sidenav/SidenavCollapse';
import SidenavRoot from 'layouts/Sidenav/SidenavRoot';
// import sidenavLogoLabel from "layouts/Sidenav/styles/sidenav";
import { UIController, setMiniSidenav } from 'context';

// eslint-disable-next-line no-unused-vars
function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = UIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    window.addEventListener('resize', handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
    let returnValue;

    if (type === 'collapse') {
      returnValue = href ? (
        <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
          <SidenavCollapse
            color={color}
            name={name}
            icon={icon}
            active={key === collapseName || key === pathname}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavCollapse
            color={color}
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName || key === pathname}
            noCollapse={noCollapse}
          />
        </NavLink>
      );
    } else if (type === 'title') {
      returnValue = (
        <SoftTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={1}
          mb={1}
          ml={1}
        >
          {title}
        </SoftTypography>
      );
    } else if (type === 'divider') {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  return (
    <SidenavRoot {...rest} dispatch="" variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox pt={2} px={4} textAlign="center">
        <SoftBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <SoftBox component="img" src={brand} alt="Peltbeam DHS Logo" width="10rem" />}
          {/* <SoftBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SoftTypography component="h6" variant="button" fontWeight="bold" mt={0.5}>
              {brandName}
              <sub style={{ fontSize: "10px" }}>IOT</sub>
            </SoftTypography>
          </SoftBox> */}
        </SoftBox>
      </SoftBox>
      <Divider />
      <List>
        <SoftTypography
          key="dashboad-pages"
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={1}
          mb={1}
          ml={1}
        >
          Dashboards
        </SoftTypography>
        <NavLink to="/dashboard" key="dashboard">
          <SidenavCollapse
            color={color}
            key="dashboard"
            name="Dashboard"
            icon={<Icon>dashboard</Icon>}
            active={'dashboard' === collapseName || 'dashboard' === pathname}
            noCollapse={true}
          />
        </NavLink>
        {renderRoutes}
      </List>
      <SoftBox pt={2} my={2} mx={2} mt="auto">
        <SoftBox mt={2}>
          <SoftButton
            style={{ pointerEvents: 'none', textTransform: 'none' }}
            variant="gradient"
            color="dark"
            fullWidth
          >
            &copy; 2023 Peltbeam Inc.
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: ''
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Sidenav;
