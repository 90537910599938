const actions = {
  SET_STATE: 'networkDevice/SET_STATE',
  LIST: 'networkDevice/LIST',
  COUNT: 'networkDevice/COUNT',
  ASSIGN: 'networkDevice/ASSIGN',
  UNASSIGN: 'networkDevice/UNASSIGN',
  UPDATE: 'networkDevice/UPDATE',
  TRIGGER_FW_UPDATE: 'networkDevice/TRIGGER_FW_UPDATE'
};

export default actions;
