import './styles.scss';
import './global-styles.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from 'App';
import ReduxToastr from 'react-redux-toastr';
import { UIControllerProvider } from 'context';
import { store, history } from 'redux/store';
import packageJson from '../package.json';
import config from './config/config';

// eslint-disable-next-line no-console
console.info(`
########################################################
# Frontend version : ${packageJson.version}
# BACKEND URL      : ${config.API_ENDPOINT}
########################################################
`);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router history={history}>
      <UIControllerProvider>
        <App />
        <ReduxToastr
          timeOut={5000}
          closeOnToastrClick
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          preventDuplicates
        />
      </UIControllerProvider>
    </Router>
  </Provider>
);
