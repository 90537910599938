import { useState, useEffect, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Sidenav from 'layouts/Sidenav';
import theme from 'assets/theme';
import routes from 'routes';
import { UIController, setMiniSidenav } from 'context';
import { ProtectedRoute } from './ProtectedRoute';
import { AuthProvider } from './context/auth.context';
import { CircularProgress } from '@mui/material';
import brand from 'assets/images/logos/Peltbeam_GrayBlue.png';
import store from 'store';
import { history } from 'redux/store';

export default function App() {
  const [controller, dispatch] = UIController();
  const { miniSidenav, direction, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const accessToken = store.get('token');
    if (accessToken && accessToken !== '' && pathname === '/login') {
      history.replace('/');
    }
  }, [pathname]);

  const renderRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return renderRoutes(route.collapse);
      }

      if (route.route) {
        if (route.protected) {
          return <ProtectedRoute exact path={route.route} component={route.component} key={route.key} />;
        }
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {pathname !== '/login' && pathname !== '/404' && store.get('token') && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Petlbeam DHS"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Suspense
        fallback={
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20px'
            }}
          >
            <CircularProgress color="info" size="1rem" disableShrink />
            <span> &nbsp; Loading...</span>
          </div>
        }
      >
        <AuthProvider>
          <Switch>
            {renderRoutes(routes)}
            <Redirect exact from="/" to="/dashboard" />
            <Redirect from="*" to="/404" />
          </Switch>
        </AuthProvider>
      </Suspense>
    </ThemeProvider>
  );
}
