import actions from './actions';

const initialState = {
  list: [],
  detail: {},
  count: 0,
  loading: false,
  error: false,
  isRemoved: false,
  isNetworkFetched: false,
  isCountFetched: false
};

export default function networkReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
