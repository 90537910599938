import { lazy } from 'react';
import { Icon } from '@mui/material';

const routes = [
  {
    type: 'none',
    name: 'Dashboard',
    key: 'dashboard',
    route: '/dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    component: lazy(() => import('pages/dashboard')),
    noCollapse: true,
    protected: true,
    roles: ['admin', 'operator']
  },
  { type: 'title', title: 'Settings', key: 'setting-pages', roles: ['admin', 'operator'] },
  {
    type: 'collapse',
    name: 'Networks',
    key: 'networks',
    route: '/networks',
    icon: <Icon fontSize="small">hub</Icon>,
    component: lazy(() => import('pages/networks')),
    noCollapse: true,
    protected: true,
    roles: ['admin', 'operator']
  },
  {
    type: 'none',
    key: 'networks',
    route: '/networks/:id/detail',
    component: lazy(() => import('pages/networks/detail')),
    noCollapse: true,
    protected: true
  },
  {
    type: 'none',
    key: 'login',
    route: '/login',
    component: lazy(() => import('pages/authentication/login')),
    noCollapse: true
  },
  {
    type: 'none',
    key: '404',
    route: '/404',
    component: lazy(() => import('pages/NotFound')),
    noCollapse: true
  }
];

export default routes;
