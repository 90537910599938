import apiClient from 'services/axios';

const API = {
  list: 'api/system/services/fwUpdates'
};

export const list = async (id, filter) => {
  const response = await apiClient.get(API.list, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};
