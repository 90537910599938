import apiClient from 'services/axios';
import store from 'store';
import { omit } from 'lodash';
import { replaceUrlIds } from 'utils';

const API = {
  list: 'api/networks',
  create: 'api/networks',
  update: 'api/networks/{networkId}',
  detail: 'api/networks/{networkId}',
  count: 'api/networks/count',
  delete: 'api/networks/{networkId}',
  networkDevices: '/api/networks/{networkId}/networkDevices',
  countNetworkDevices: 'api/networks/{networkId}/networkDevices/count',
  assignDevice: '/api/networks/{networkId}/networkDevices',
  unassignDevice: 'api/networks/{networkId}/networkDevices/{deviceId}',
  updateDevice: 'api/networks/{networkId}/networkDevices/{deviceId}/name'
};

export const list = async (filter) => {
  let response;
  if (filter.token) {
    const newFilter = omit(filter, ['token']);
    response = await apiClient({
      method: 'get',
      url: API.list,
      params: newFilter,
      headers: {
        authorization: filter.token
      }
    });

    if (response) {
      store.set('token', filter.token);
    }
  } else {
    response = await apiClient.get(API.list, { params: filter });
  }

  if (response) {
    return response.data;
  }
  return false;
};

export const count = async (filter) => {
  const response = await apiClient.get(API.count, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const create = async (body) => {
  const response = await apiClient.post(API.create, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const update = async (id, body) => {
  const parsedUrl = replaceUrlIds(API.update, [{ networkId: id }]);
  const response = await apiClient.patch(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const detail = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.detail, [{ networkId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const remove = async (id) => {
  const parsedUrl = replaceUrlIds(API.delete, [{ networkId: id }]);
  const response = await apiClient.delete(parsedUrl);
  if (response) {
    return response.data;
  }
  return false;
};

export const networkDevices = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.networkDevices, [{ networkId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const countNetworkDevices = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.countNetworkDevices, [{ networkId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const assignDevice = async (id, body) => {
  const parsedUrl = replaceUrlIds(API.assignDevice, [{ networkId: id }]);
  const response = await apiClient.post(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const unassignDevice = async (id, deviceId) => {
  const parsedUrl = replaceUrlIds(API.unassignDevice, [{ networkId: id }, { deviceId }]);
  const response = await apiClient.delete(parsedUrl);
  if (response.status === 204) {
    return true;
  }
  return false;
};

export const updateDevice = async (id, deviceId, body) => {
  const parsedUrl = replaceUrlIds(API.updateDevice, [{ networkId: id }, { deviceId }]);
  const response = await apiClient.patch(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};
