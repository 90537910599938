import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import boxShadows from 'assets/theme/base/boxShadows';
import pxToRem from 'assets/theme/functions/pxToRem';

const { light, white, sliderColors } = colors;
const { borderRadius, borderWidth } = borders;
const { sliderBoxShadow } = boxShadows;

const slider = {
  styleOverrides: {
    root: {
      width: '100%',
      padding: '0px 0',

      '& .MuiSlider-active, & .Mui-focusVisible': {
        boxShadow: 'none !important'
      },

      '& .MuiSlider-valueLabel': {
        color: light.main
      }
    },

    rail: {
      height: pxToRem(5),
      // backgroundColor: gradients.info.main,
      borderRadius: borderRadius.sm
    },

    track: {
      // backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
      height: pxToRem(7),
      position: 'relative',
      top: pxToRem(2),
      border: 'none',
      borderRadius: borderRadius.lg
      // zIndex: 1,
    },

    thumb: {
      width: pxToRem(16),
      height: pxToRem(16),
      backgroundColor: white.main,
      zIndex: 10,
      boxShadow: sliderBoxShadow.thumb,
      border: `${borderWidth[1]} solid ${sliderColors.thumb.borderColor}`,

      '&:hover': {
        boxShadow: 'none'
      }
    }
  }
};

export default slider;
