import actions from './actions';

const initialState = {
  detail: {},
  count: 0,
  loading: false,
  list: [],
  error: false,
  isUnassigned: false,
  isNetworkDevicesFetched: false,
  isCountFetched: false,
  triggerFwUpdateDetail: {},
  triggerFwUpdateLoading: false,
  triggerFwUpdateError: false
};

export default function networkDeviceReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
