import { all, takeEvery, call, put } from 'redux-saga/effects';
import { list, count, create, remove, getFwUpdateStatus, setFwUpdateStatus } from 'services/apis/fwUpdate';
import actions from './actions';
import { toastr } from 'react-redux-toastr';

function* loading(isLoading = false, error = false) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: isLoading,
      error
    }
  });
}

export function* LIST({ payload }) {
  const { id, filter } = payload;
  yield loading(true);

  const response = yield call(list, id, filter);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response,
        loading: false,
        error: false,
        isFwUpdateAuthorizesFetched: true,
        isCountFetched: false
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* LIST_DROPDOWN({ payload }) {
  const { id, filter } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      listDropDownLoading: true,
      error: false
    }
  });

  const response = yield call(list, id, filter);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        listDropDown: response,
        listDropDownLoading: false,
        error: false
      }
    });
  }

  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        listDropDownLoading: false,
        error: true
      }
    });
  }
}

export function* COUNT({ payload }) {
  const { id } = payload;
  let filter = {};
  yield loading(true);

  if (payload.filter) {
    filter = payload.filter;
  }

  const response = yield call(count, id, filter);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        count: response.count,
        isFwUpdateAuthorizesFetched: false,
        isCountFetched: true
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* CREATE({ payload }) {
  const { id, body, filter } = payload;
  let whereCondition = {
    where: { networkId: id }
  };
  yield loading(true);

  if (filter.where) {
    whereCondition = { where: filter.where };
  }

  const response = yield call(create, id, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false,
        isFwUpdateAuthorizesFetched: false,
        isCountFetched: false
      }
    });

    yield put({ type: actions.COUNT, payload: { id, filter: whereCondition } });
    toastr.success('succeeded', 'Fw update authorize is created!');
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* DELETE({ payload }) {
  const { networkId, authorizeId, filter } = payload;
  let whereCondition = {};
  yield loading(true);

  if (filter.where) {
    whereCondition = { where: filter.where };
  }

  const response = yield call(remove, networkId, authorizeId);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false,
        isRemoved: true
      }
    });

    yield put({ type: actions.COUNT, payload: { id: networkId, filter: whereCondition } });
    toastr.success('succeeded', 'Fw update authorize removed!');
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* GET_FW_UPDATE_STATUS({ payload }) {
  const { id } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      fwUpdateStatusLoading: true,
      fwUpdateStatusError: false
    }
  });

  const response = yield call(getFwUpdateStatus, id);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fwUpdateStatusDetail: response,
        fwUpdateStatusLoading: false,
        fwUpdateStatusError: false
      }
    });
  }

  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fwUpdateStatusLoading: false,
        fwUpdateStatusError: true
      }
    });
  }
}

export function* SET_FW_UPDATE_STATUS({ payload }) {
  const { id, body } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      fwUpdateStatusLoading: true,
      fwUpdateStatusError: false
    }
  });

  const response = yield call(setFwUpdateStatus, id, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fwUpdateStatusDetail: response,
        fwUpdateStatusLoading: false,
        fwUpdateStatusError: false
      }
    });

    toastr.success('succeeded', 'Fw updated!');
  }

  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fwUpdateStatusLoading: false,
        fwUpdateStatusError: true
      }
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.LIST_DROPDOWN, LIST_DROPDOWN),
    takeEvery(actions.COUNT, COUNT),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.GET_FW_UPDATE_STATUS, GET_FW_UPDATE_STATUS),
    takeEvery(actions.SET_FW_UPDATE_STATUS, SET_FW_UPDATE_STATUS)
  ]);
}
