import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/functions/pxToRem';

const { transparent } = colors;

const select = {
  styleOverrides: {
    select: {
      display: 'grid',
      alignItems: 'center',
      padding: `0 ${pxToRem(12)} !important`,
      height: pxToRem(22),
      '& .Mui-selected': {
        backgroundColor: transparent.main
      }
    },

    selectMenu: {
      background: 'none',
      height: 'none',
      minHeight: 'none',
      overflow: 'unset'
    },

    icon: {
      display: 'inline-block'
    }
  }
};

export default select;
