const actions = {
  SET_STATE: 'network/SET_STATE',
  LIST: 'network/LIST',
  COUNT: 'network/COUNT',
  CREATE: 'network/CREATE',
  DETAIL: 'network/DETAIL',
  UPDATE: 'network/UPDATE',
  DELETE: 'network/DELETE'
};

export default actions;
